<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách ca làm việc'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission(permission.create)">
          <b-button
           @click="handleCreate"
            v-b-modal.modal-upsert-shiftWork
            variant="primary"
            size="sm"
          >
            <strong><i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới</strong>
          </b-button>   
        </div>
      </template>
      <template v-slot:preview>
        <div class="col-md-12 mb-6">
          <b-row>
            <b-col md="3" class="pl-0">
              <Autosuggest
                :model="searchShiftWork.name"
                :suggestions="filteredShiftWorkOptions"
                :placeholder="'ca làm việc'"
                :limit="100"
                @select="onSelected('ShiftWork', $event)"
                @change="onInputShiftWorkChange"
                suggestionName="suggestionName"
              />
            </b-col>
            <b-col md="3">
              <Autosuggest
                :model="searchStore.name"
                :suggestions="filteredStoreOptions"
                :placeholder="'cửa hàng'"
                :limit="100"
                @select="onSelected('Store', $event)"
                @change="onInputStoreChange"
                suggestionName="suggestionName"
              />
            </b-col>
            <b-col md="3">
              <b-form-group>
                <date-picker
                  placeholder="Nhập ngày áp dụng"
                  class="form-control form-control-sm date-picker"
                  :config="dpConfigs.date"
                  v-model="searchApplydate"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col md="3"> </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="1" class="pl-0">
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="fetchShiftWorkConfig"
                >Lọc</b-button
              >
            </b-col>
          </b-row>
        </div>
        <b-table
          :items="listShiftWorkConfig"
          :fields="fields"
          striped
          responsive="sm"
          class="table-bordered table-hover"
          no-local-sorting
          :busy="onLoading"
        >
          <template #cell(EmployeesDetail)="row">
            <b-button
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
              variant="outline-primary"
            >
              {{ row.detailsShowing ? 'Ẩn' : 'Hiện' }} danh sách nhân viên
            </b-button>
          </template>
          <template #row-details="row">
            <b-card no-body>
              <b-list-group flush>
                <b-list-group-item
                  ><b-row>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Quản lý cửa hàng:</b></b-col
                    >
                    <b-col>{{ row.item.managerName }}</b-col>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Trợ lý cửa hàng:</b></b-col
                    >
                    <b-col>{{ row.item.assistantName }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item>
                  <b-row>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Nhân viên kỹ thuật:</b></b-col
                    >
                    <b-col>{{ row.item.technicalName }}</b-col>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Nhân viên thu ngân:</b></b-col
                    >
                    <b-col>{{ row.item.cashierName }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Tiếp đón khách hàng:</b></b-col
                    >
                    <b-col>{{ row.item.customerReceptionName }}</b-col>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Nhân viên giao hàng:</b></b-col
                    >
                    <b-col>{{ row.item.shipperName }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item>
                  <b-row>
                    <b-col sm="3" class="text-sm-right"
                      ><b>Nhân viên CDPK:</b></b-col
                    >
                    <b-col>{{ row.item.accessoryStaffName }}</b-col>
                  </b-row></b-list-group-item
                >
              </b-list-group>
            </b-card>
          </template>
          <template #cell(name)="data">
            {{
              `${data.item.name} (${data.item.fromHour} - ${data.item.toHour})`
            }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>

                <b-dropdown-item
                  v-b-modal.modal-upsert-shiftWork
                  @click="editItem(row.item, constAction.UPDATE)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="checkPermission(permission.edit)"
                  v-b-modal.modal-upsert-shiftWork
                  @click="editItem(row.item, constAction.UPDATE)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission(permission.delete)"
                  @click="showDeleteAlert(row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchShiftWorkConfig"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <UpsertShiftWorkConfigModal
          v-on:fetchShiftWorkConfig="fetchShiftWorkConfig"
          :shiftWorkConfig="shiftWorkConfigSelected"
          :action="action"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import Loader from '@/view/content/Loader.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import UpsertShiftWorkConfigModal from '../../components/shiftWorkConfig/UpsertShiftWorkConfigModal';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatDate } from '../../../utils/date';
import Swal from 'sweetalert2';
import { removeAccents } from '@/utils/common';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import localData from '@/utils/saveDataToLocal';

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      selectedStore: {
        required,
      },
      storeId: {
        required,
      },
      selectedShiftWork: {
        required,
      },
      shiftWorkId: {
        required,
      },
    },
  },
  created() {
    this.fetchShiftWorkConfig();
    this.fetchStoreByUser();
    this.fetchShiftWork();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình ca làm việc', route: 'shiftWorkConfig' },
      { title: 'Danh sách ca làm việc' },
    ]);
  },

  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          thClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Ca làm việc',
          sortable: false,
          thClass: 'text-center',
        },
        {
          key: 'EmployeesDetail',
          label: 'Danh sách nhân viên',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'applyDate',
          label: 'Ngày áp dụng',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: (value) => {
            return formatDate(value, 'DD/MM/YYYY');
          },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'actionProClass',
          thClass: 'actionProClass',
        },
      ],
      constAction: {
        UPDATE: 1,
        CREATE: 2,
        UPSERT: 3,
      },
      permission: {
        viewList: 'SHIFTWORK_CONFIG_VIEW',
        delete: 'SHIFTWORK_CONFIG_DELETE',
        create: 'SHIFTWORK_CONFIG_INSERT',
        edit: 'SHIFTWORK_CONFIG_UPDATE',
      },
      searchShiftWork: { name: '', id: null },
      filteredShiftWorkOptions: [],
      shiftWorkOptions: [],
      page: 1,
      pageSize: 10,
      listShiftWorkConfig: [],
      searchApplydate: null,
      searchStore: {
        name: '',
        id: null,
      },
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredStoreOptions: [],
      storeOptions: [],
      totalItem: 0,
      onLoading: false,
      shiftWorkConfigSelected: null,
      action: '',
      totalPages: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
    };
  },
  methods: {
    editItem(item, action) {
      this.action = action;
      this.shiftWorkConfigSelected = { ...item };
    },
    onInputShiftWorkChange(text) {
      this.searchShiftWork.name = text;
      const filterData = this.shiftWorkOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredShiftWorkOptions = [...filterData];
      if (this.searchShiftWork.name === '') {
        this.filteredShiftWorkOptions = this.shiftWorkOptions;
      }
    },
    fetchShiftWork: async function () {
      ApiService.setHeader();
      ApiService.get('/shift-work/').then((response) => {
        const listShiftWork = response.data.data;
        this.shiftWorkOptions = listShiftWork.map((element) => {
          return {
            id: element.id,
            name: element.name,
            fromHour: element.fromHour,
            toHour: element.toHour,
            suggestionName: `${element.name} ( ${element.fromHour} - ${element.toHour})`,
          };
        });
        this.filteredShiftWorkOptions = [...this.shiftWorkOptions];
      });
    },
    onSelected(type, option) {
      switch (type) {
        case 'Store': {
          this.searchStore = option.item;
          break;
        }
        case 'ShiftWork': {
          this.searchShiftWork = option.item;
          break;
        }
        default:
          break;
      }
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa ca làm việc!',
        text: 'Bạn có chắc muốn xóa ca làm việc này không  ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    onInputStoreChange(text) {
      this.searchStore.name = text;
      const filterData = this.storeOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredStoreOptions = [...filterData];
      if (this.searchStore.name === '') {
        this.filteredStoreOptions = this.storeOptions;
      }
    },
    deleteItem: async function (item) {
      ApiService.setHeader();
      ApiService.delete('shift-work-config' + '/' + item.id)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchShiftWorkConfig();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(
            response.data ? response.data.message : 'Lỗi hệ thống'
          );
        });
    },
    fetchShiftWorkConfig: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const param = {
        page: this.page,
        pageSize: this.pageSize,
        searchApplydate: this.searchApplydate
          ? moment(this.searchApplydate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        searchShiftWork: this.searchShiftWork.id,
        searchStore: this.searchStore.id,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('/shift-work-config/', paramQuery)
        .then(({ data }) => {
          this.listShiftWorkConfig = [];
          this.totalItem = data.data.total;
          this.totalPages = data.data.pages > 0 ? data.data.pages : 1;
          this.listShiftWorkConfig = data.data.dataset;
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          this.storeOptions = stores.map((item) => {
            return {
              id: item.id,
              suggestionName: item.name,
              name: item.name,
            };
          });

          this.filteredStoreOptions = [...this.storeOptions];
        }
      });
    },
    handleCreate() {
      this.action = this.constAction.CREATE;
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  components: {
    KTCodePreview,
    Loader,
    UpsertShiftWorkConfigModal,
    Autosuggest,
    datePicker,
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
input.form-control {
  border: 1px solid #ced4da;
}
</style>
